<template>
  <section class="section">
    <div class="columns">
      <div class="column is-9">
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },

  created() {
    window.scrollTo(0, 0)
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile'
    })
  }
}
</script>
